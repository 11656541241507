import { DPApps } from "@src/apps/apps.types";
import { lazy } from "react";
import { AppToInitialize } from "./app.util";
import ManagementPlannerRouter from "./management-planner/router";
import PawnShopCustomerRouter from "./pawn-shop-customer/router";
import PawnShopRouter from "./pawn-shop/router";
import SwishRouter from "./swish-out/router";
import "./app.scss";
if (AppToInitialize === DPApps.p2) {
	import("./p2/p2.scss");
}
if (AppToInitialize === DPApps.flows) {
	import("./flows/flows.scss");
}

const MRM = lazy(() => import("../router"));
const P2Router = lazy(() => import("./p2/router"));
const FlowRouter = lazy(() => import("./flows/router"));
const TemplateRouter = lazy(() => import("./template/router"));

const DPRouter = ({ history }) => {
	switch (AppToInitialize) {
		case DPApps.p2: {
			return <P2Router history={history} />;
		}
		case DPApps.flows: {
			return <FlowRouter history={history} />;
		}
		case DPApps.template: {
			return <TemplateRouter history={history} />;
		}
		case DPApps.swish_out: {
			return <SwishRouter history={history} />;
		}
		case DPApps.pawn_shop: {
			return <PawnShopRouter history={history} />;
		}
		case DPApps.pawn_shop_customer: {
			return <PawnShopCustomerRouter history={history} />;
		}
		case DPApps.management_planner: {
			return <ManagementPlannerRouter history={history} />;
		}
		default: {
			return <MRM history={history} />;
		}
	}
};

export default DPRouter;
