import Error404Page from "@src/views/404";
import "antd4/dist/antd.css";
import { ConnectedRouter } from "connected-react-router";
import { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Loader from "../../components/Layout/Loader";

const ManagementPlanner = ({ history }) => {
	const routes = [
		// {
		//   path: '/user/login',
		//   Component: lazy(() => import('./views/login/login.view')),
		//   exact: true,
		//   hidden: false,
		//   props: { logo: PSLogo },
		// },
		// {
		//   path: '/user/login/verify',
		//   Component: lazy(() => import('./views/login/login-verify.view')),
		//   exact: true,
		//   hidden: false
		// },
		// {
		//   path: '/user/login/otp',
		//   Component: lazy(() => import('./views/login/login-otp.view')),
		//   exact: true,
		//   hidden: false
		// },
		{
			path: "/mp",
			Component: lazy(() => import("./views/MP.view")),
			exact: true,
			hidden: false,
			props: {},
		},
	];

	return (
		// @ts-ignore
		<ConnectedRouter history={history}>
			{/* <DPIndexLayout defaultPath="/mp"> */}
			<Switch>
				<Route exact path="/" render={() => <Redirect to="/mp" />} />
				{routes.map(
					({ path, Component, exact, hidden, props = {} }) =>
						!hidden && (
							<Route
								path={path}
								key={path}
								exact={exact}
								render={(render_props) => (
									<Suspense fallback={<Loader fullScreen={false} />}>
										<Component {...render_props} {...props} />
									</Suspense>
								)}
							/>
						),
				)}
				<Route component={Error404Page} />
			</Switch>
			{/* </DPIndexLayout> */}
		</ConnectedRouter>
	);
};

export default ManagementPlanner;
